import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";

import Layout from "../components/Layout";
import "../style/careers.scss";
import ArrowIcon from "/assets/icons/mui/arrow-right-long.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";

const AboutPage = ({ data, pageContext, path }) => {
  const [currentValueMach9, setCurrentValueMach9] = useState(1);

  const { frontmatter } = data.markdownRemark;
  const formRef = useRef();

  const content = frontmatter[pageContext.language || "en"];
  const homeUrl =
  pageContext.language === "en" ? "/" : `/${pageContext.language}/`;

  return (
    <Layout language={pageContext.language || "en"} path={path}>
      <div id="careers-page">
        <section id="careers-hero">
          <div className="section-content">
            <div>
              <div className="catchy">{content.herosection.title1}</div>
              <h2>{content.herosection.title2}</h2>
              <p>{content.herosection.description}</p>
            </div>
            <div
              style={{ display: "flex", alignSelf: "start", marginTop: "60px" }}
            >
              <img
                src={content.herosection.heroimage.publicURL}
                style={{
                  width: "100%",
                  height: "auto",
                  maxWidth: "35rem",
                  margin: "auto",
                  filter: "grayscale(1)",
                  borderRadius: "8px",
                }}
              />
            </div>
          </div>
        </section>
        <section id="careers-values">
          <div>
            <div className="catchy">{content.valuessection.title1}</div>
            <h2>{content.valuessection.title2}</h2>
          </div>
          <div>
            <div
              id="values-container"
              className="cards"
              style={{
                position: "relative",
                zIndex: "1",
              }}
            >
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="careerSwiper"
              >
                {content.valuessection.values?.map((value) => (
                  <SwiperSlide>
                    <div className="card">
                      <div
                        style={{
                          backgroundImage: `url(${value.valueimage.publicURL})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          position: "absolute",
                          inset: "0",
                          minHeight: "100%",
                          minWidth: "100%",
                          filter: "opacity(0.35)",
                          zIndex: -1,
                        }}
                      ></div>
                      <h3>{value.imagetitle}</h3>
                      <p>{value.imagedescription}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div
              className="projector"
              style={{
                transform: "translate(-50%, -75%) rotate(-90deg)",
                zIndex: 0,
              }}
            ></div>
            <div
              style={{
                backgroundImage: `url(/static/assets/projector.png)`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                width: "100%",
                height: "10rem",
              }}
            ></div>
          </div>
        </section>
        <section id="careers-crew">
          <div>
            <div className="catchy">{content.crewsection.title1}</div>
            <h2>{content.crewsection.title2}</h2>
          </div>
          <div className="job-section">
            {content && content?.crewsection?.jobSection?.map((item) => {
              return (
                <div>
                  <img src="/static/assets/services/services/service-bg.png" />

                  <h3>{item.title}</h3>
                  <p>{item.description.slice(0,300) + '...'}</p>
                  <Link to={`${homeUrl}jobs?job=${item.title}`}>
                  <button className="mach9">
                    <div>
                     {item.button}
                      <ArrowIcon />
                    </div>
                  </button>
                  </Link>
                </div>
              );
            })}
          </div>
          {/* <div className="crew-members">
            <div id="crew-members-container">
              <div id="crew-members">
                <div className="card">
                  <div>
                    <div className="catchy">{content.crewsection.subtitle}</div>
                    <h3>{content.crewsection.noopenposition}</h3>
                  </div>
                  <p>{content.crewsection.description}</p>
                  <div
                    style={{
                      marginTop: "4rem",
                      width: "25rem",
                      maxWidth: "100%",
                    }}
                  >
                    <form
                      ref={formRef}
                      action="https://mach9.us21.list-manage.com/subscribe/post?u=62ad7343ef0e4943943c53ebd&amp;id=de80344cbb&amp;f_id=009780e1f0"
                      method="post"
                      id="mc-embedded-subscribe-form"
                      name="mc-embedded-subscribe-form"
                      class="validate"
                      target="_self"
                      novalidate=""
                    >
                      <div id="mc_embed_signup_scroll">
                        <h2>{content.title}</h2>
                        <div class="mc-field-group">
                          <label for="mce-EMAIL">
                            {content.crewsection.emailfield}
                          </label>
                          <input
                            type="email"
                            name="EMAIL"
                            class="required email"
                            id="mce-EMAIL"
                            required=""
                            placeholder="johndoe@gmail.com"
                            style={{ maxWidth: "100%", width: "25rem" }}
                          />
                        </div>
                        <br />
                        <div id="mce-responses" class="clear foot">
                          <div
                            class="response"
                            id="mce-error-response"
                            style={{ display: "none" }}
                          ></div>
                          <div
                            class="response"
                            id="mce-success-response"
                            style={{ display: "none" }}
                          ></div>
                        </div>
                        <div
                          ariaHidden="true"
                          style={{ position: "absolute", left: "-5000px" }}
                        >
                          <input
                            type="text"
                            name="b_62ad7343ef0e4943943c53ebd_de80344cbb"
                            tabindex="-1"
                            value=""
                          />
                        </div>
                        <div class="optionalParent">
                          <div class="clear foot">
                            <div
                              style={{ maxWidth: "15rem" }}
                              className="button mach9"
                              onClick={() => {
                                if (formRef.current) formRef.current.submit();
                              }}
                            >
                              <div>
                                <input
                                  type="submit"
                                  name="subscribe"
                                  id="mc-embedded-subscribe"
                                  class="button"
                                  value={content.crewsection.cta}
                                  style={{ cursor: "pointer" }}
                                />
                                <ArrowIcon />
                              </div>
                            </div>
                            <p style={{ margin: "4rem 0 0" }}>
                              <a
                                href="http://eepurl.com/ilSOFI"
                                title="Mailchimp - email marketing made easy and fun"
                              >
                                <span
                                  style={{
                                    display: "inline-block",
                                    backgroundColor: "transparent",
                                    borderRadius: "4px",
                                  }}
                                >
                                  <img
                                    class="refferal_badge"
                                    src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg"
                                    alt="Intuit Mailchimp"
                                    style={{
                                      width: "220px",
                                      height: "40px",
                                      display: "flex",
                                      padding: "2px 0px",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  />
                                </span>
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </section>
      </div>
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default AboutPage;

export const pageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(
      frontmatter: { en: { templateKey: { eq: "careers-page" } } }
    ) {
      frontmatter {
        en {
          herosection {
            title1
            title2
            description
            heroimage {
              publicURL
            }
          }
          valuessection {
            title1
            title2
            values {
              valueimage {
                publicURL
              }
              imagetitle
              imagedescription
            }
          }
          crewsection {
            title1
            title2
            subtitle
            noopenposition
            description
            emailfield
            cta
            jobSection {
              title
              description
              button
            }
          }
        }
        it {
          herosection {
            title1
            title2
            description
            heroimage {
              publicURL
            }
          }
          valuessection {
            title1
            title2
            values {
              valueimage {
                publicURL
              }
              imagetitle
              imagedescription
            }
          }
          crewsection {
            title1
            title2
            subtitle
            noopenposition
            description
            emailfield
            cta
            jobSection {
              title
              description
              button
            }
          }
        }
        es {
          herosection {
            title1
            title2
            description
            heroimage {
              publicURL
            }
          }
          valuessection {
            title1
            title2
            values {
              valueimage {
                publicURL
              }
              imagetitle
              imagedescription
            }
          }
          crewsection {
            title1
            title2
            subtitle
            noopenposition
            description
            emailfield
            cta
          }
        }
      }
    }
  }
`;
